import React from "react";
import Logo from "./assets/logo.png";
import Promo1 from "./assets/promo-1.jpeg";
import Promo2 from "./assets/promo-2.jpeg";
import Promo3 from "./assets/promo-3.jpeg";
import FreeDel from "./assets/freedelivery.png";
import { Link } from "react-router-dom";
import {
  FaLocationDot,
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa6";

function App() {
  return (
    <div className="flex flex-col justify-center font-poppins">
      <header>
        <div className="flex bg-[url('./assets/bg.jpg')] bg-cover w-full min-h-screen ">
          <div className="w-full min-h-screen bg-gradient-to-t from-slate-900">
            <div className="flex flex-col justify-center text-center items-center md:px-40 px-20 ">
              <img
                src={Logo}
                alt="Logo"
                className="md:w-52 w-72 md:pt-20 pt-16"
              />
              <h1 className="text-3xl md:text-5xl text-white font-bold pt-20">
                We offer quality daily essentials online.
              </h1>
              <div className="flex flex-col justify-center pt-10 text-white">
                <h1 className="text-xl font-semibold">Our Branches</h1>
                <ul className="flex gap-12 py-3">
                  <li className="flex gap-2 items-center">
                    <FaLocationDot />
                    Kawdana
                  </li>
                  <li className="flex gap-2 items-center">
                    <FaLocationDot />
                    Panadura
                  </li>
                </ul>

                <p></p>
              </div>

              {/* <img src={Dev} alt="dev" className="w-40 pt-5" /> */}
              {/* <div className="md:py-32 py-24 flex gap-3">
            <input
              placeholder="Enter email address"
              className="w-60 h-10 border border-slate-100 rounded-lg  bg-transparent px-4 items-center text-white"
            />
            <button className=" px-2 bg-rose-900 text-sm  text-white font-medium h-10 rounded-md hover:bg-rose-950 cursor-pointer duration-200">
              Get Notified
            </button>
          </div> */}
            </div>
          </div>
        </div>
      </header>
      <section>
        <div className="container mx-auto h-full max-w-full px-20 py-10 bg-[#C7E8CA]">
          <div className="flex flex-col justify-center">
            <h1 className="text-center text-2xl uppercase font-bold text-red-700">
              Seasonal Promotions
            </h1>
            <div className="md:flex md:justify-center gap-12 pt-10">
              <a href={Promo1} target="_blank">
                <img
                  src={Promo1}
                  className="w-80 rounded-xl py-2 hover:scale-110 transition duration-500 cursor-pointer shadow-xl"
                  alt=""
                />
              </a>
              <a href={Promo2} target="_blank">
                <img
                  src={Promo2}
                  className="w-80 rounded-xl py-2 hover:scale-110 transition duration-500 cursor-pointer shadow-xl"
                  alt=""
                />
              </a>
              <a href={Promo3} target="_blank">
                <img
                  src={Promo3}
                  className="w-80 rounded-xl py-2 hover:scale-110 transition duration-500 cursor-pointer shadow-xl"
                  alt=""
                />
              </a>
            </div>
            <div className="flex flex-col pt-10">
              <img src={FreeDel} className="w-40" alt="" />
              <p className="pl-4 text-xl font-medium">
                <span className="font-bold">Free delivery</span> available for
                over {""}
                <span className="font-bold">Rs. 6000</span> orders.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div>
          <div id="contact" className="bg-[#1A5D1A] md:px-16 px-8 py-5">
            <h2 className="text-center py-4 text-2xl text-white">Contact Us</h2>
            <div className="md:flex block justify-between">
              <div className="text-white md:w-1/2 w-full justify-center">
                <img src={Logo} alt="Logo" className="w-24 mx-auto md:mx-0" />
                <h1 className="text-xl font-semibold uppercase tracking-wider py-2 md:text-left text-center">
                  the good farmer
                </h1>
                <p className="md:text-left text-center">
                  We strive to serve fresh fruits,vegetables & quality daily
                  essentials with world-class best practices
                </p>
                <div className="flex gap-3 py-3">
                  <div className="flex flex-col">
                    <p className="py-2">
                      <FaLocationDot />
                    </p>
                    <p className="py-2">
                      <FaPhone />
                    </p>
                    <p className="py-2">
                      <FaEnvelope />
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p>14, Kawdana , Attidiya Road, Dehiwala, Sri Lanka</p>
                    <p className="pt-3">0112 710 840 | 077 889 4490</p>
                    <p className="py-2">info@goodfarmer.lk</p>
                  </div>
                </div>
                <div className="py-2">
                  <h3 className="font-medium text-lg tracking-wider">
                    Our Social Links
                  </h3>
                  <p>
                    <a
                      href="https://www.facebook.com/thegoodfarmersl"
                      className="flex pl-2 items-center gap-2 hover:opacity-60 duration-300"
                    >
                      <FaFacebook /> Facebook
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.instagram.com/goodfarmersl/"
                      className="flex pl-2 items-center gap-2 hover:opacity-60 duration-300"
                    >
                      <FaInstagram /> Instagram
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://wa.me/+94778894490"
                      className="flex pl-2 items-center gap-2 hover:opacity-60 duration-300"
                    >
                      <FaWhatsapp /> WhatsApp
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex justify-start">
                <iframe
                  className="border-none md:w-[350px] w-[250px] md:h-[350px] h-[250px]"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.3961311047965!2d79.87079277789303!3d6.843022775506185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25b1c33dbb79d%3A0xdc0325bfa554bbf0!2sThe%20Good%20Farmer!5e0!3m2!1sen!2slk!4v1702877499224!5m2!1sen!2slk"
                  title="Google Maps"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className=" bg-[#004225] flex justify-center  bottom-0 py-4 px-4 text-sm md:text-lg">
        <p className="text-white ">
          &copy; 2023 IT Department | <a href="nolimit.lk"> NOLIMIT.</a> All
          rights reserved.
        </p>
      </footer>
    </div>
  );
}

export default App;
